var adminMenu = function () {
	var submenuLinks, toggleMenuBtn, menuBtnText, menuBtnIcon, mobileMenuBtn, mainMenu, menuWrap, mainContent,
	initVariables = function () {
            	toggleMenuBtn = document.querySelector(".toggleMenu");
            	menuBtnText = document.querySelector(".menuBtnText");
            	menuBtnIcon = toggleMenuBtn.querySelector("img");
            	mobileMenuBtn = document.querySelector(".mobileMenuBtn");
            	mainMenu = document.querySelector(".mainMenu");
            	menuWrap = document.querySelector(".menuWrapper");
            	submenuLinks = [].slice.call(document.querySelectorAll(".subMenuLink"));
            	mainContent = document.querySelector(".mainContent");
	},
	init = function () {
            	initVariables();
            	
            	submenuLinks.forEach(function (el) {
                	el.addEventListener("click", SubMenu.Toggle);
            	});

            	mobileMenuBtn.addEventListener("click", MainMenu.Toggle);
            	toggleMenuBtn.addEventListener("click", MainMenu.Toggle);

            	window.addEventListener("resize", MainMenu.CloseResize);
	},
	MainMenu = (function(){
		var toggle = function (e) {
	            	e.preventDefault();
	            	const menuBtn = e.currentTarget;
	            	if (menuBtn.getAttribute("aria-expanded") === "false") open();
	            	else close();
		},
		open = function () {
			btnsSetExpanded("true");
	            	menuWrap.classList.add("menuOpen");
	            	/* remove line below after release of admin, accounting, wms*/
	            	mainContent.classList.remove("fullWidth");
	            	mainMenu.classList.remove("hide");
	            	updateButton("close");
	            	if (isMobileMenu == "true") document.body.style.overflowY = "hidden";
		},
		close = function () {
	            	SubMenu.CloseAll();
	            	btnsSetExpanded("false");
	            	menuWrap.classList.remove("menuOpen");
	            	/* remove line below after release of admin, accounting, wms*/
	            	mainContent.classList.add("fullWidth");
	            	updateButton("open");
	            	if (isMobileMenu == "true") document.body.removeAttribute("style");
	            	
	            	setTimeout(function () {
	            		// let animation play then hide
	                	mainMenu.classList.add("hide");
	            	}, 400);
		},
		isMobileMenu = function(){
			if (window.innerWidth < 1200) return "true";
			else return "false";
		},
		btnsSetExpanded = function(isExpanded){
			toggleMenuBtn.setAttribute("aria-expanded", isExpanded);
	            	mobileMenuBtn.setAttribute("aria-expanded", isExpanded);
		},
		updateButton = function(isOpenClosed){
			if(isOpenClosed == "open"){
				menuBtnIcon.classList.add("rotate180");
				menuBtnText.innerHTML = "Menu";
			}else{
				menuBtnIcon.classList.remove("rotate180");
				menuBtnText.innerHTML = "Close Admin Menu";
			}
		},
		closeResize = function(){
			if(toggleMenuBtn.getAttribute("aria-expanded") == "true"){
				close();
			}
		};
		return{
			Toggle: toggle,
			CloseResize: closeResize
		};
	})(),
	SubMenu = (function () {
            	var toggle = function (e) {
                	e.preventDefault();
                	const menuLink = e.currentTarget;
               	if (menuLink.getAttribute("aria-expanded") === "false") {
                    		open(menuLink);
                	} else {
                    		close(menuLink);
                	}
            	},
            	open = function (menuLinkToOpen) {
                	closeAll();
                	const menuToOpen = menuLinkToOpen.nextElementSibling;
                	const menuParent = menuLinkToOpen.parentNode;
                	menuParent.classList.add("activeMenu");
                	menuLinkToOpen.setAttribute("aria-expanded", "true");
                	menuLinkToOpen.classList.add("bkgdLtGray");
                	menuLinkToOpen.querySelector("svg").removeAttribute("class");
                	menuToOpen.classList.remove("hide");
            	},
            	close = function (menuLinkToClose) {
                	const menuToClose = menuLinkToClose.nextElementSibling;
                	const menuParent = menuLinkToClose.parentNode;
                	menuParent.classList.remove("activeMenu");
                	menuLinkToClose.setAttribute("aria-expanded", "false");
                	menuLinkToClose.classList.remove("bkgdLtGray");
                	menuLinkToClose.querySelector("svg").setAttribute("class", "rotate180");
                	menuToClose.classList.add("hide");
            	},
            	closeAll = function (e) {
                	submenuLinks.forEach(function (el) {
                    		if (el.getAttribute("aria-expanded") === "true") {
                       	 	close(el);
                    		}
                	});
            	};
            	return {
                	Toggle: toggle,
                	CloseAll: closeAll
            	};
	})();

	return {
		Init: init
	};
};

(function () {
	var AdminMenu = new adminMenu();
	if (document.querySelector(".mainMenu") !== null) {
		AdminMenu.Init();
	}
})();